var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.personalDetails()
        },
      },
    },
    [
      _c(
        "b-row",
        {
          staticStyle: { "min-height": "calc(100vh - 180px)" },
          attrs: { "align-h": "center", "align-v": "stretch" },
        },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", xxl: "6", "align-self": "center" } },
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-6",
                      staticStyle: { height: "150px" },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "b-row",
                        {
                          staticClass: "pl-5 pb-3",
                          staticStyle: { height: "100%" },
                          attrs: { "align-v": "end" },
                        },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c(
                              "h1",
                              {
                                staticClass: "mb-3 heading text-primary",
                                staticStyle: {
                                  "font-size": "46px",
                                  "line-height": "100%",
                                },
                              },
                              [_vm._v(" Contact Us ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "align-self": "start" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "pl-5 pr-5" },
                        [
                          _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                            _c(
                              "h2",
                              { staticClass: "heading text-primary mb-4" },
                              [_vm._v("Get In Touch")]
                            ),
                            _c(
                              "address",
                              { staticStyle: { "font-size": "20px" } },
                              [
                                _vm._v(" E: hello@bywinona.com"),
                                _c("br"),
                                _vm._v(" T: (844) 929-1586 "),
                                _c("br"),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "mt-2 mr-2",
                                attrs: {
                                  href: "https://www.facebook.com/winonalife",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/facebook.svg"),
                                    alt: "facebook",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "mt-2 mr-2",
                                attrs: {
                                  href: "https://twitter.com/wearewinona",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/twitter.svg"),
                                    alt: "twitter",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "mt-2 mr-2",
                                attrs: {
                                  href: "https://www.instagram.com/winonalife/",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/instagram.svg"),
                                    alt: "instagram",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "8" } },
                            [
                              _c(
                                "b-alert",
                                {
                                  attrs: {
                                    show: _vm.alert.show,
                                    variant: _vm.alert.type,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.alert.message))]
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _c("div", {
                                      staticClass: "mt-5 d-md-none",
                                    }),
                                    _c(
                                      "h2",
                                      {
                                        staticClass: "heading text-primary",
                                        attrs: { for: "subject" },
                                      },
                                      [_vm._v(" Submit an inquiry: ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { role: "group" },
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            "append-to-body": "",
                                            "calculate-position":
                                              _vm.withPopper,
                                            label: "text",
                                            options: _vm.options,
                                            reduce: (options) => options.text,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "open-indicator",
                                              fn: function ({ attributes }) {
                                                return [
                                                  _c(
                                                    "b-icon",
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "chevron-down",
                                                        },
                                                      },
                                                      "b-icon",
                                                      attributes,
                                                      false
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.selected,
                                            callback: function ($$v) {
                                              _vm.selected = $$v
                                            },
                                            expression: "selected",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { role: "group" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "input-live" } },
                                          [_vm._v("Email Address:")]
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "pl-0 fs-mask",
                                          attrs: {
                                            id: "email",
                                            type: "email",
                                            autocomplete: "off",
                                            state: _vm.emailState,
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.email,
                                            callback: function ($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email",
                                          },
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "input-live-feedback",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " The email must have '@email.com' "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  this.name
                                    ? _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-3",
                                              attrs: { role: "group" },
                                            },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "name" } },
                                                [_vm._v("Your Name: ")]
                                              ),
                                              _c("b-form-input", {
                                                staticClass: "pl-0 fs-mask",
                                                attrs: {
                                                  id: "name",
                                                  type: "text",
                                                  autocomplete: "off",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.name,
                                                  callback: function ($$v) {
                                                    _vm.name = $$v
                                                  },
                                                  expression: "name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  this.phone
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: this.name ? 6 : 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-3",
                                              attrs: { role: "group" },
                                            },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "phone" } },
                                                [_vm._v("Phone Number:")]
                                              ),
                                              _c("b-form-input", {
                                                staticClass: "pl-0 fs-mask",
                                                attrs: {
                                                  id: "phone",
                                                  type: "text",
                                                  autocomplete: "off",
                                                  readonly: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value: _vm.phone,
                                                  callback: function ($$v) {
                                                    _vm.phone = $$v
                                                  },
                                                  expression: "phone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-4",
                                        attrs: { role: "group" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "textarea" } },
                                          [_vm._v("Message:")]
                                        ),
                                        _c("b-form-textarea", {
                                          staticClass: "pl-0",
                                          staticStyle: { overflow: "auto" },
                                          attrs: {
                                            id: "textarea",
                                            rows: "3",
                                            "max-rows": "6",
                                            autocomplete: "off",
                                            required: "",
                                          },
                                          on: {
                                            focus: function ($event) {
                                              return _vm.toggleBodyClass(
                                                "addClass",
                                                "stopScroll"
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.toggleBodyClass(
                                                "removeClass",
                                                "stopScroll"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.message,
                                            callback: function ($$v) {
                                              _vm.message = $$v
                                            },
                                            expression: "message",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right mb-3",
                                      staticStyle: { "padding-bottom": "8rem" },
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: { "min-width": "200px" },
                                          attrs: {
                                            pill: "",
                                            size: "lg",
                                            type: "submit",
                                            variant: "info",
                                          },
                                        },
                                        [_vm._v("Submit")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }